import React, { useState } from 'react';
import Sidebar from '../components/Sidebar'; // Ensure this path is correct
import PendingDoctorTable from '../components/PendingDoctorTable'; // Ensure this path is correct
import ApprovedDoctorTable from '../components/ApprovedDoctorTable'; // Ensure this path is correct
import RejectedDoctorTable from '../components/RejectedDoctorTable'; // Ensure this path is correct
import PendingHospitalTable from '../components/PendingHospitalTable'; // Ensure this path is correct
import ApprovedHospitalTable from '../components/ApprovedHospitalTable'; // Ensure this path is correct
import RejectedHospitalTable from '../components/RejectedHospitalTable'; // Ensure this path is correct
import './HomePage.css'; // Optional, if you have specific styles for HomePage

const HomePage = () => {
  const [selectedTable, setSelectedTable] = useState('doctors');

  const handleItemClick = (item) => {
    setSelectedTable(item);
  };

  return (
    <div className="d-flex">
      <Sidebar onItemClick={handleItemClick} />
      <div className="content with-padding">
        <br />
        {selectedTable === 'doctors' && <PendingDoctorTable />}
        {selectedTable === 'hospitals' && <PendingHospitalTable />}
        {selectedTable === 'approvedDoctors' && <ApprovedDoctorTable />} {/* Adjust based on your data */}
        {selectedTable === 'approvedHospitals' && <ApprovedHospitalTable />} {/* Adjust based on your data */}
        {selectedTable === 'rejectedDoctors' && <RejectedDoctorTable />} {/* Adjust based on your data */}
        {selectedTable === 'rejectedHospitals' && <RejectedHospitalTable />} {/* Adjust based on your data */}
      </div>
    </div>
  );
};

export default HomePage;
