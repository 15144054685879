import React, { useEffect, useState } from 'react';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton
} from '@coreui/react';
import axios from 'axios';

const columns = [
    { key: 'email', label: 'Email', _props: { scope: 'col' } },
    { key: 'phone', label: 'Phone', _props: { scope: 'col' } },
    { key: 'name', label: 'Name', _props: { scope: 'col' } },
    { key: 'description', label: 'Description', _props: { scope: 'col' } },
    { key: 'profilePic', label: 'Profile Picture', _props: { scope: 'col' } },
    { key: 'address', label: 'Address', _props: { scope: 'col' } },
    { key: 'status', label: 'Status', _props: { scope: 'col' } },
    { key: 'actions', label: 'Actions', _props: { scope: 'col' } }, // New column for actions
];

const PendingHospitalTable = () => {
    const [hospitals, setHospitals] = useState([]);
    const [statusMap, setStatusMap] = useState({}); // To track status changes

    useEffect(() => {
        // Fetch data from API
        const fetchHospitals = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const response = await axios.get('https://api.nillq.com/api/admin/pending/hospitals', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const transformedData = response.data.map(hospital => ({
                    _id: hospital._id,
                    email: hospital.email,
                    phone: hospital.phone,
                    name: hospital.name,
                    description: hospital.description,
                    profilePic: <img src={hospital.profilePic} alt="Profile" style={{ width: '50px', height: '50px' }} />,
                    address: `${hospital.address.street}, ${hospital.address.locality}, ${hospital.address.city}, ${hospital.address.state}, ${hospital.address.pinCode}`,
                    status: (
                        <select
                            value={statusMap[hospital._id] || hospital.approved}
                            onChange={(e) => setStatusMap(prev => ({ ...prev, [hospital._id]: e.target.value }))}
                        >
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>
                        </select>
                    ),
                    actions: (
                        <CButton
                            color="primary"
                            onClick={() => handleStatusChange(hospital._id)}
                        >
                            Change
                        </CButton>
                    ), // Button for actions
                }));
                setHospitals(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchHospitals();
    }, [statusMap]); // Refetch when statusMap changes

    const handleStatusChange = async (hospitalId) => {
        try {
            const newStatus = statusMap[hospitalId];
            const token = localStorage.getItem('jwtToken');
            await axios.post(`https://api.nillq.com/api/admin/change-status/hospital/${hospitalId}`, {
                status: newStatus
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Refetch data to update table
            const response = await axios.get('https://api.nillq.com/api/admin/pending/hospitals', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }); const transformedData = response.data.map(hospital => ({
                _id: hospital._id,
                email: hospital.email,
                phone: hospital.phone,
                name: hospital.name,
                description: hospital.description,
                profilePic: <img src={hospital.profilePic} alt="Profile" style={{ width: '50px', height: '50px' }} />,
                address: `${hospital.address.street}, ${hospital.address.locality}, ${hospital.address.city}, ${hospital.address.state}, ${hospital.address.pinCode}`,
                status: (
                    <select
                        value={statusMap[hospital._id] || hospital.approved}
                        onChange={(e) => setStatusMap(prev => ({ ...prev, [hospital._id]: e.target.value }))}
                    >
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                    </select>
                ),
                actions: (
                    <CButton
                        color="primary"
                        onClick={() => handleStatusChange(hospital._id)}
                    >
                        Change
                    </CButton>
                ), // Button for actions
            }));
            setHospitals(transformedData);
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    return (
        <CTable responsive striped hover>
            <CTableHead>
                <CTableRow>
                    {columns.map((col) => (
                        <CTableHeaderCell key={col.key} {...col._props}>{col.label}</CTableHeaderCell>
                    ))}
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {hospitals.map((hospital) => (
                    <CTableRow key={hospital._id}>
                        {columns.map((col) => (
                            <CTableDataCell key={col.key}>{hospital[col.key]}</CTableDataCell>
                        ))}
                    </CTableRow>
                ))}
            </CTableBody>
        </CTable>
    );
};

export default PendingHospitalTable;
