import React, { useState } from 'react';
import {
  CSidebar,
  CSidebarHeader,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CNavGroup,
  CButton
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilPaperclip, cilLockLocked } from '@coreui/icons';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ onItemClick }) => {
  const [activeItem, setActiveItem] = useState('');
  const navigate = useNavigate();

  const handleNavItemClick = (e, item) => {
    e.preventDefault(); // Prevent default link behavior
    setActiveItem(item); // Set the active item
    onItemClick(item); // Call the passed handler
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('jwtToken');
    // Redirect to the login page
    navigate('/signin');
  };

  return (
    <CSidebar className="border-end full-height" colorScheme="dark">
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand>NillQ Admin Panel</CSidebarBrand>
      </CSidebarHeader>
      <CSidebarNav>
        <CNavTitle>Admin dashboard</CNavTitle>

        <CNavGroup
          toggler={
            <>
              <CIcon customClassName="nav-icon" icon={cilPaperclip} />
              Pending registrations
            </>
          }
        >
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'doctors')}>
            <span className="nav-icon">
              {activeItem === 'doctors' && <span className="nav-icon-bullet"></span>}
            </span>
            Doctors
          </CNavItem>
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'hospitals')}>
            <span className="nav-icon">
              {activeItem === 'hospitals' && <span className="nav-icon-bullet"></span>}
            </span>
            Hospitals
          </CNavItem>
        </CNavGroup>

        <CNavGroup
          toggler={
            <>
              <CIcon customClassName="nav-icon" icon={cilPaperclip} />
              Approved registrations
            </>
          }
        >
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'approvedDoctors')}>
            <span className="nav-icon">
              {activeItem === 'approvedDoctors' && <span className="nav-icon-bullet"></span>}
            </span>
            Doctors
          </CNavItem>
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'approvedHospitals')}>
            <span className="nav-icon">
              {activeItem === 'approvedHospitals' && <span className="nav-icon-bullet"></span>}
            </span>
            Hospitals
          </CNavItem>
        </CNavGroup>

        <CNavGroup
          toggler={
            <>
              <CIcon customClassName="nav-icon" icon={cilPaperclip} />
              Rejected registrations
            </>
          }
        >
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'rejectedDoctors')}>
            <span className="nav-icon">
              {activeItem === 'rejectedDoctors' && <span className="nav-icon-bullet"></span>}
            </span>
            Doctors
          </CNavItem>
          <CNavItem href="#" onClick={(e) => handleNavItemClick(e, 'rejectedHospitals')}>
            <span className="nav-icon">
              {activeItem === 'rejectedHospitals' && <span className="nav-icon-bullet"></span>}
            </span>
            Hospitals
          </CNavItem>
        </CNavGroup>

        <div className="sidebar-footer">
          <CButton
            color="danger"
            onClick={handleLogout}
            className="logout-button"
          >
            <CIcon icon={cilLockLocked} /> Logout
          </CButton>
        </div>
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;
