import React, { useEffect, useState } from 'react';
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormSelect,
  CButton
} from '@coreui/react';
import axios from 'axios';

const columns = [
  { key: 'email', label: 'Email', _props: { scope: 'col' } },
  { key: 'phone', label: 'Phone', _props: { scope: 'col' } },
  { key: 'name', label: 'Name', _props: { scope: 'col' } },
  { key: 'specialization', label: 'Specialization', _props: { scope: 'col' } },
  { key: 'profilepic', label: 'Profile Picture', _props: { scope: 'col' } },
  { key: 'address', label: 'Address', _props: { scope: 'col' } },
  { key: 'sex', label: 'Sex', _props: { scope: 'col' } },
  { key: 'age', label: 'Age', _props: { scope: 'col' } },
  { key: 'doctorUID', label: 'Doctor UID', _props: { scope: 'col' } },
  { key: 'status', label: 'Status', _props: { scope: 'col' } },
  { key: 'actions', label: 'Actions', _props: { scope: 'col' } },
];

const PendingDoctorTable = () => {
  const [doctors, setDoctors] = useState([]);
  const [statusMap, setStatusMap] = useState({}); // Track status selections

  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      console.log(token);
      const response = await axios.get('https://api.nillq.com/api/admin/pending/doctors', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDoctors(response.data);
      const initialStatusMap = response.data.reduce((map, doctor) => {
        map[doctor._id] = doctor.approved;
        return map;
      }, {});
      setStatusMap(initialStatusMap);
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };
  
  

  const handleStatusChange = (doctorId, newStatus) => {
    // Update local state
    setStatusMap({ ...statusMap, [doctorId]: newStatus });
  };

  const handleSaveStatusChange = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(`https://api.nillq.com/api/admin/change-status/doctor/${doctorId}`, {
        status: statusMap[doctorId]
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Fetch updated data
      fetchDoctors();
    } catch (error) {
      console.error('Error changing status:', error);
    }
  };
  

  return (
    <CTable striped hover>
      <CTableHead>
        <CTableRow>
          {columns.map((col) => (
            <CTableHeaderCell key={col.key} {...col._props}>{col.label}</CTableHeaderCell>
          ))}
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {doctors.map((doctor) => (
          <CTableRow key={doctor._id}>
            <CTableDataCell>{doctor.email}</CTableDataCell>
            <CTableDataCell>{doctor.phone}</CTableDataCell>
            <CTableDataCell>{doctor.name}</CTableDataCell>
            <CTableDataCell>{doctor.specialization}</CTableDataCell>
            <CTableDataCell>
              <img src={doctor.profilePic} alt="Profile" style={{ width: '50px', height: '50px' }} />
            </CTableDataCell>
            <CTableDataCell>
              {`${doctor.address.street}, ${doctor.address.locality}, ${doctor.address.city}, ${doctor.address.state}, ${doctor.address.pinCode}`}
            </CTableDataCell>
            <CTableDataCell>{doctor.sex}</CTableDataCell>
            <CTableDataCell>{new Date().getFullYear() - new Date(doctor.dob).getFullYear()}</CTableDataCell>
            <CTableDataCell>{doctor.uid}</CTableDataCell>
            <CTableDataCell>
              <CFormSelect
                value={statusMap[doctor._id] || doctor.approved}
                onChange={(e) => handleStatusChange(doctor._id, e.target.value)}
              >
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </CFormSelect>
            </CTableDataCell>
            <CTableDataCell>
              <CButton
                color="primary"
                onClick={() => handleSaveStatusChange(doctor._id)}
              >
                Change
              </CButton>
            </CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </CTable>
  );
};

export default PendingDoctorTable;
