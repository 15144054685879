import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import DoctorRegistration from './components/registration/DoctorRegistration';
// import HospitalRegistration from './components/registration/HospitalRegistration';
import HomePage from './components/admin/pages/HomePage';
import SignIn from './components/admin/pages/SignIn';
import PrivateRoute from './components/admin/pages/PrivateRoute';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/register/doctor" element={<DoctorRegistration />} />
          <Route path="/register/hospital" element={<HospitalRegistration />} /> */}
          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="/dashboard/admin" element={<PrivateRoute element={HomePage} />} /> */}
          <Route path="/" element={<PrivateRoute element={HomePage} />} />
          {/* <Route path="/" element={<h2>NillQ home</h2>} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
